import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { config } from "../../Constants";
import { RootState } from "../../store";
import { addPackageBuyInfo, updateState, togglePackageBuyModal } from "../../store/packages/packageReducer";
import { getAuthTokenConfig } from "../../helpers/others/getAuthTokenConfig";
import { handleApiError } from "../../helpers/error-handlers/handleApiError";
import styles from "./BuyPackage.module.css";
import InputField from "../../components/common/input-fields/InputField";
import Button from "./Button";

const BuyPackage = () => {

    const dispatch = useDispatch();
    const packageState = useSelector((state: RootState) => state.packageState);
    const { 
        validationError, 
        packageItemInAction,
        packageBuyInfo,
    } = packageState;
    const { paymentMethod, clientName, clientEmail, clientPhone } = packageBuyInfo;
   
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        dispatch(addPackageBuyInfo({ name, value }));
    }

    const purchasePackage = async() => {

        if(!paymentMethod || !clientName || !clientEmail || !clientPhone) {
            dispatch(updateState({
                name: "validationError",
                value: true
            }));
            return;
        }

        const endpoint = config.url.BACKEND_API_URL + "/packages/buy/private";

        const authConfig = getAuthTokenConfig();
        const requestBody = {
            stripePaymentIntentId: null,
            selectedPackage: packageItemInAction,
            ...packageBuyInfo
        };

        dispatch(togglePackageBuyModal(null));

        dispatch(updateState({
            name: "actionMessage",
            value: "Buying..."
        }));

        try {

            await axios.post(endpoint, requestBody, authConfig);
            dispatch(updateState({
                name: "actionMessage",
                value: "Package buy is successful"
            }));

        } catch(error) {
            const { message } = handleApiError(error);
            dispatch(updateState({
                name: "actionMessage",
                value: message
            }));
        }
    
    }

    return (
        <div className={styles.buy_package}>
            <h2>You are buying {packageItemInAction?.title} (€{packageItemInAction?.price.toFixed(2)})</h2>
            <div className={styles.buyer_info}>
                <InputField 
                    labelText="Payment Method"
                    name="paymentMethod"
                    required={true}
                    value={packageBuyInfo.paymentMethod}
                    handleChange={handleChange}
                    validationError={validationError}
                    validationErrorMessage="payment method can't be blank"
                />
                <InputField 
                    labelText="Full Name"
                    name="clientName"
                    required={true}
                    value={packageBuyInfo.clientName}
                    handleChange={handleChange}
                    validationError={validationError}
                    validationErrorMessage="name can't be blank"
                />
                <InputField 
                    labelText="Email"
                    name="clientEmail"
                    required={true}
                    value={packageBuyInfo.clientEmail}
                    handleChange={handleChange}
                    validationError={validationError}
                    validationErrorMessage="email can't be blank"
                />
                <InputField 
                    labelText="Phone"
                    name="clientPhone"
                    required={true}
                    value={packageBuyInfo.clientPhone}
                    handleChange={handleChange}
                    validationError={validationError}
                    validationErrorMessage="phone can't be blank"
                />
            </div>
            <div className={styles.save_btn_wrapper}>
                <Button 
                    customClassName="save_btn"
                    buttonText="Buy"
                    handleClick={purchasePackage}
                />
            </div>
        </div>
    );

}

export default BuyPackage;