import { useSelector, useDispatch } from "react-redux";
import { IPackage } from "../../types/models/Package";
import { RootState } from "../../store";
import { 
    updateState, 
    openEditPackageModal,
    togglePackageBuyModal, 
    toggleDeletePrompt 
} from "../../store/packages/packageReducer";
import { FETCH_PACKAGE_DATA } from "../../store/packages/constants";
import useWindowWidth from "../../hooks/useWindowWidth";
import styles from "./PackageTable.module.css";
import DropdownList from "./DropdownList";
import ActionButtons from "./ActionButtons";
import ResponsivePackageTable from "./ResponsivePackageTable";

const PackageTable = () => {

    const dispatch = useDispatch();
    const packageState = useSelector((state: RootState) => state.packageState);
    const { devicesWidth: { isLargeDevice } } = useWindowWidth();
    const { packageList, searchText, skip, limit, totalPackageCount } = packageState;

    const handleClick = (args: { actionType: string, packageItem: IPackage}) => {

        const { actionType, packageItem } = args;

        if(actionType === "delete") {
            dispatch(toggleDeletePrompt(packageItem));
            return;
        }

        if(actionType === "buy") {
            dispatch(togglePackageBuyModal(packageItem));
            return;
        }

        dispatch(openEditPackageModal(packageItem))

    }

    const handleDropdownChange = (viewItem: { _id: number, limit: number | string }) => {

        if(totalPackageCount <= 5) return;

        dispatch(updateState({
            name: "limit",
            value: viewItem.limit
        }))

        dispatch({ 
            type: FETCH_PACKAGE_DATA, 
            payload: { 
                searchText, 
                skip, 
                limit: viewItem.limit,
                fetchTreatmentData: false 
            } 
        });

    }

  
    return (
        <div className={styles.package_table}> 
            <div className={styles.table_header}>
                <h2>Packages</h2>
                <DropdownList 
                    customClassName="view_dropdown_list"
                    dropdownListFor="view_item"
                    data={[
                        { _id: 1, limit: 5 },
                        { _id: 2, limit: 10 },
                        { _id: 3, limit: "All" },
                    ]}
                    nameKey="limit"
                    selectedValue={limit}
                    clickHandler={handleDropdownChange}
                />
            </div>
            {
                isLargeDevice
                ?
                <table>
                    <thead>
                        <tr>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Price</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {packageList.map(packageItem => {

                            const { _id, title, description, price } = packageItem;

                            return (
                                <tr key = {_id}>
                                    <td>{title}</td>
                                    <td>{description}</td>
                                    <td className={styles.package_price_td}>
                                        <span className = {styles.package_price}>€{price.toFixed(2)}</span>
                                    </td>
                                    <td>
                                        <ActionButtons 
                                            packageItem={packageItem}
                                            showButtonText={true}
                                            handleClick={handleClick}
                                        />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                :
                <ResponsivePackageTable 
                    handleClick={handleClick}
                />
            }
        </div>
    );

}

export default PackageTable;